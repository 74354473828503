<template>
  <b-card no-body>
    <b-card-body class="py-1 px-2">
      <b-row>
        <b-col class="mb-md-0 mb-2" cols="12" md="4">
          <b-form-group
            label="Terme de recherche"
            label-for="main-agency-admin-first-name"
          >
            <b-input-group>
              <b-form-input
                v-model="criteria.search"
                placeholder="Ville, guide, etc."
                type="search"
                @input="(val) => $emit('update', criteria)"
              />
              <b-input-group-append>
                <span class="input-group-text">
                  <b-button
                    @click="$emit('update', criteria)"
                    variant="link"
                    class="m-0 p-0"
                  >
                    <feather-icon icon="SearchIcon" />
                  </b-button>
                </span>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          v-show="!showAdvancedFilters"
          class="my-auto pl-0"
          cols="12"
          md="4"
        >
          <b-button
            class="p-0 pt-1"
            variant="link"
            @click="showAdvancedFilters = !showAdvancedFilters"
          >
            Recherche avancée
          </b-button>
        </b-col>
        <template v-if="showAdvancedFilters">
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>Statut du groupe</label>
            <v-select
              class="w-100"
              v-model="criteria.status"
              @input="(val) => $emit('update', criteria)"
              :options="groupStatus"
              label="text"
              :reduce="(text) => text.value"
            />
          </b-col>
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>Type de groupe</label>
            <v-select
              label="text"
              v-model="criteria.type"
              :options="availableGroupTypes"
              :reduce="(text) => text.value"
              @input="(val) => $emit('update', criteria)"
            />
          </b-col>
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>Accompagnateur</label>
            <v-select
              :options="availableGuides"
              v-model="criteria.guide"
              label="text"
              @input="(val) => $emit('update', criteria)"
            />
          </b-col>
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>Année de départ</label>
            <v-select
              :options="availableYears"
              v-model="criteria.departureYear"
              class="w-100"
              @input="(val) => $emit('update', criteria)"
            />
          </b-col>
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>Mois de départ</label>
            <v-select
              multiple
              label="label"
              v-model="criteria.departureMonth"
              :options="departureMonths"
              @input="(val) => $emit('update', criteria)"
            />
          </b-col>
        </template>
      </b-row>
      <b-row v-if="showAdvancedFilters">
        <b-col cols="12" class="d-flex justify-content-end">
          <b-button
            class="p-0 pt-1"
            variant="link"
            @click="showAdvancedFilters = !showAdvancedFilters"
          >
            Cacher la recherche avancée
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BCollapse,
  BButton,
  VBToggle,
  BButtonGroup,
  BInputGroup,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    BCollapse,
    BButton,
    BButtonGroup,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: {
    availableGuides: {
      type: Array,
      required: true,
    },
    availableYears: {
      type: Array,
      required: true,
    },
    availableGroupTypes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showAdvancedFilters: false,
      criteria: {
        search: this.$route.query.search || "",
        type: "all",
        guide: "",
        status: "all_active",
        departureYear: "",
        departureMonth: "",
      },
      groupStatus: [
        { text: "Tous", value: "all" },
        { text: "Tous les groupes actifs", value: "all_active" },
        { text: "En préparation", value: "draft" },
        { text: "Publié", value: "published" },
        { text: "Archivé", value: "archived" },
        { text: "Terminé", value: "completed" },
        { text: "Annulé", value: "cancelled" },
      ],
      departureMonths: [
        { value: "0", label: "Janvier" },
        { value: "1", label: "Février" },
        { value: "2", label: "Mars" },
        { value: "3", label: "Avril" },
        { value: "4", label: "Mai" },
        { value: "5", label: "Juin" },
        { value: "6", label: "Juillet" },
        { value: "7", label: "Août" },
        { value: "8", label: "Septembre" },
        { value: "9", label: "Octobre" },
        { value: "10", label: "Novembre" },
        { value: "11", label: "Décembre" },
      ],
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
